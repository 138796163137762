
export const configUrl =
  process.env.REACT_APP_SECRET_API === "prod" ?
  {
    http: 'https://www.iqidao.com/',
    cdnUrl: 'https://oss.iqidao.com/front_project/game/',
    trackUrl: 'https://track.iqidao.com/s/',
    kidUrl: 'https://kid.iqidao.com/web/gospirit_webview/',
    kidHomeUrl : 'https://kid.iqidao.com',
    preHomeUrl: 'https://pre-www.iqidao.com/'
  }
  :
  process.env.REACT_APP_SECRET_API === "test" ?
  {
    http: 'https://test.iqidao.com/',
    cdnUrl: 'https://oss-test.iqidao.com/front_project/game/',
    trackUrl: 'https://alitest-track.iqidao.com/s/',
    kidUrl: 'https://alitest-kid.iqidao.com/web/gospirit_webview/',
    kidHomeUrl : 'https://alitest-kid.iqidao.com',
    preHomeUrl: 'https://pre-test.iqidao.com/'

  }
  :
  process.env.REACT_APP_SECRET_API === "dev" ?
  {
    http: 'https://dev.iqidao.com/',
    cdnUrl: 'https://oss-dev.iqidao.com/front_project/game/',
    trackUrl: 'https://alitest-track.iqidao.com/s/',
    kidUrl: 'https://alidev-kid.iqidao.com/web/gospirit_webview/',
    kidHomeUrl : 'https://alidev-kid.iqidao.com',
    preHomeUrl: 'https://pre-test.iqidao.com/'

  }
  : {
    http: '',
    cdnUrl: "http://localhost:3001/",
    trackUrl: 'https://alitest-track.iqidao.com/s/',
    kidUrl: 'https://alidev-kid.iqidao.com/web/gospirit_webview/',
    kidHomeUrl : 'https://alidev-kid.iqidao.com',
    preHomeUrl: 'https://pre-test.iqidao.com/'

  }
