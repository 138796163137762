// types
import { createSlice } from '@reduxjs/toolkit';
import {IAuthStore} from "@/models/auth";

// initial state
const initialState:IAuthStore = {
  mobile: '',
  userModalVisible: false,
  tempPhone: '',
  tempSignCode: '',
};


// ==============================|| SLICE - MENU ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      Object.assign(state, {
        user: action.payload,
        logged : true
      })

      console.log(action.payload)

      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('uid', action.payload.userId)
    },

    updateMobile(state, action) {
      state.mobile = action.payload
    },

    updateUser(state, action) {
      Object.assign(state, {
        user: action.payload,
        logged : true
      })

      console.log(action.payload)

    },

    updateUserModalVisible(state, action) {
      state.userModalVisible = action.payload
    },

    updateTempPhone(state, action) {
      state.tempPhone = action.payload
    },

    updateTempSignCode(state, action) {
      state.tempSignCode = action.payload
    },

    updateLogged(state, action) {
      state.logged = false
    },

    updateNickName(state, action){
      if(state.user){
        state.user.nickName = action.payload
      }
    },

    updateBaseInfo(state, action){
      if(state.user){
        state.user.birthday = action.payload.birthday
        state.user.gender = action.payload.gender
      }
    },

    logout(state) {
      localStorage.removeItem('token')
      localStorage.removeItem('uid')
      state.user = undefined;
      state.logged = false;
    },
  }
});

export default auth.reducer;

export const {
  login,
  updateMobile,
  updateUser,
  updateNickName,
  updateBaseInfo,
  updateLogged,
  logout,
  updateTempPhone,
  updateTempSignCode,
  updateUserModalVisible,
} = auth.actions;
