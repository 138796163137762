import Loadable from "@/components/common/Loadable";
import {lazy} from "react";
import { configUrl } from "@/config/urlConfig";

const WebviewLayout = Loadable(lazy(() => import('@/layout/Webview')));
const Webview = Loadable(lazy(() => import('@/pages/Webview')));
// 课程详情页不要页脚，所以写在这里
const CourseDetail = lazy(() => import('@/pages/CourseDetail'));


const Routes = {
  path: '/',
  element: <WebviewLayout/>,
  children: [
    {
      path: 'course/detail',
      element: <CourseDetail/>
    },
    {
      path: 'teachers',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/teachers`} />
    },
    {
      path: 'articles',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/articles?category=1`} />
    },
    {
      path: 'about',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/about`} />
    },
    {
      path: 'faq',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/faq`} />
    },
    {
      path: 'copyright',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/copyright`} />
    },
    {
      path: 'policy',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/terms`} />
    },
    {
      path: 'agreement',
      element: <Webview url={`${configUrl.preHomeUrl}ver3/agreement`}/>
    },
  ]
};

export default Routes;
