import Loadable from "@/components/common/Loadable";
import {lazy} from "react";

const IndexLayout = Loadable(lazy(() => import('@/layout/Index')));
const Index = lazy(() => import('@/pages/Index'));
const LevelIntro = lazy(() => import('@/pages/LevelIntro'));
const CourseList = lazy(() => import('@/pages/CourseList'));
const Download = lazy(() => import('@/pages/Download'));
const Order = lazy(() => import('@/pages/Order'));

const Routes = {
  path: '/',
  element: <IndexLayout/>,
  children: [
    {
      path: '',
      element: <Index/>
    },
    {
      path: 'levelIntro',
      element: <LevelIntro/>
    },
    {
      path: 'course/list',
      element: <CourseList/>
    },
    {
      path: 'download',
      element: <Download/>
    },
    {
      path: 'order',
      element: <Order/>
    },
  ]
};

export default Routes;
