import Loadable from "@/components/common/Loadable";
import {lazy} from "react";

const BlankLayout = Loadable(lazy(() => import('@/layout/Blank')));

const Routes = {
  path: '/a',
  element: <BlankLayout/>,
  children: [
  ]
};

export default Routes;
