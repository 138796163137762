import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';
// 引入第三方
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ReduxProvider store={store}>
    <ConfigProvider locale={zh_CN}>
      <BrowserRouter basename={''}>
        <Routes />
      </BrowserRouter>
    </ConfigProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
