import { useRoutes } from 'react-router-dom';

// project import
import BlankRoutes from './BlankRoutes';
import IndexRoutes from './IndexRoutes';
import WebviewRoutes from './WebviewRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function Routes() {
  return useRoutes([BlankRoutes, IndexRoutes, WebviewRoutes]);
}
