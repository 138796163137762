// types
import { ICommonStore } from '@/models/common';
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState:ICommonStore = {
  drawerVisible: false,
  giftModalVisible: false
};


// ==============================|| SLICE - MENU ||============================== //

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateDrawerVisible(state, action){
      state.drawerVisible = action.payload;
    },

    updateGiftModalVisible(state, action){
      state.giftModalVisible = action.payload;
    }
  }
});

export default common.reducer;

export const {
  updateDrawerVisible,
  updateGiftModalVisible
} = common.actions;
